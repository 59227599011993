<template>
  <validation-observer ref="observerFinancing">
    <form>
      <validation-provider
        v-slot="{ errors }"
        name="source_of_financing"
        rules="required"
      >
        <v-autocomplete
          :error-messages="errors"
          auto-select-first
          v-model="idFiscalFund"
          :items="fiscalFundList"
          :label="$t('label.source_financing')"
          data-vv-name="select"
          required
        ></v-autocomplete>
      </validation-provider>
    </form>
  </validation-observer>
</template>

<script>
import { FiscalFoundRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      idFiscalFund: null,
      fiscalFundList: [],
    };
  },
  methods: {
    async validateFormFiscal() {
      return await this.$refs.observerFinancing.validate();
    },
    reloadComponent(id) {
      this.idFiscalFund = id;
    },
    getFiscalFund() {
      return this.idFiscalFund;
    },
    currentFiscalFund() {
      let current = this.fiscalFundList.filter((element) => {
        if (element.value == this.idFiscalFund) return element;
      });
      return current[0].text;
    },
  },
  created() {
    FiscalFoundRequest.findAllFiscalFounds({})
      .then((res) => {
        let { success, message, data } = res.data.data;
        if (success) {
          for (var [index, value] of Object.entries(data.objects)) {
            let fiscalFundItem = {
              text: value.key,
              value: value.idFiscalFund,
            };
            this.fiscalFundList.push(fiscalFundItem);
          }
        } else {
          this.$toast.error(message);
        }
      })
      .catch((error) => {
        this.$toast.error(error);
      });
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
