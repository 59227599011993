<template>
  <v-dialog v-model="dialogM.dialogRemmisionChange" persistent max-width="650">
    <v-card>
      <v-card-title class="p-3">
        {{ $t("global.change_remission") }}
      </v-card-title>
      <v-container>
        <div class="row">
          <div class="col-md-12 pt-0 pb-0">
            <v-autocomplete
              outlined
              v-model="remmisionSelect"
              :items="remmisionChangeList"
              :label="$t('label.remission')"
            />
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <div align="justify" class="alert alert-dark" role="alert">
              <i class="feather icon-alert-triangle"></i>
              {{ $t("remission.title_change_remission") }}
            </div>
          </div>
        </div>

        <div class="col-md-12 text-center">
          <button
            class="btn btn-outline-success mr-4 mt-4"
            @click="changeRemmision()"
            :disabled="!diableButton"
          >
            <i class="feather icon-refresh-ccw mr-2"></i>
            {{ $t("global.change_remission") }}
          </button>
          <button class="btn btn-outline-danger mt-4" @click="closed()">
            <i class="feather icon-x mr-1"></i> {{ $t("global.cancel") }}
          </button>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { SwalConfirm } from "@/core/SwalAlert";
import { UtilFront } from "@/core";
import { RemissionRequest } from "@/core/request";

export default {
  props: {
    modelChange: Object,
    remmisionChangeList: Array,
    dialogM: Object,
    alert: Function,
    originId: 0,
    reloadProductUpdate: Function,
  },
  data() {
    return {
      remmisionSelect: null,
      loading: false,
    };
  },
  computed: {
    diableButton() {
      return this.remmisionSelect != null;
    },
  },
  methods: {
    closed() {
      this.remmisionSelect = null;
      this.dialogM.dialogRemmisionChange = false;
    },
    async changeRemmision() {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html:
          "El cambio de entrada a <b>" +
          this.remmisionSelect +
          " </b> no suma o resta cantidades al momento de hacer el movimiento, favor de validar las cantidades al concluir esta acción.",
      });
      if (!isConfirmed) {
        return;
      }
      if (
        this.modelChange.remmisionViewList.length > 0 &&
        this.remmisionSelect != ""
      ) {
        this.loading = UtilFront.getLoadding();
        let data = {
          idRemmision: this.remmisionSelect,
          productRemmisionList: this.modelChange.remmisionViewList,
          userBranchOffice: {
            idUser: UtilFront.getDataUser().idUser,
            idBranchOffice: this.originId,
          },
        };
        RemissionRequest.changeRemmision(data)
          .then((response) => {
            let { data } = response.data;
            let { success, message } = data;
            if (success) {
              this.loading.hide();
              this.modelChange.remmisionViewList = [];
              this.reloadProductUpdate();
              this.closed();
              this.alert("success", "Succces_Remmision_Update_0002");
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.loading.hide();
          });
      } else {
        this.alert("error", "Error_Remmision_Update_0001");
      }
    },
  },
};
</script>