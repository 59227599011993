<template>
  <div class="mr-5 ml-5">
    <v-data-table
      :show-select="changeRemission"
      v-model="modelChange.remmisionViewList"
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      item-key="idProductRemmision"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- (Eliminacion de REGISTRO) -->
        <v-tooltip top v-if="deleteItemRemmi">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="deleteRecordRemmi(item)"
              v-bind="attrs"
              v-on="on"
            >
              feather icon-trash-2
            </v-icon>
          </template>
          <span>{{ $t("global.remove_product") }} </span>
        </v-tooltip>
        <!-- (Modificación de REGISTRO) -->

        <v-tooltip top v-if="editProduct">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              small
              v-bind="attrs"
              v-on="on"

              color="#E2E2E2"
              v-if=" item.nonConform ">
                  mdi mdi-square-edit-outline 
            </v-icon>
          </template>
          <span>No es posible editar este registro.</span><br>
          <span>(Fue etiquetado como producto</span><br>
          <span>no conforme)</span>
        </v-tooltip>


        <v-tooltip top v-if="editProduct">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small

              @click="openModalEdit(item)"
              v-bind="attrs"
              v-on="on"
              v-if=" !item.nonConform "
            >
              mdi mdi-square-edit-outline 
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>

        <div v-if="!editProduct && !deleteItemRemmi">-</div>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import { SwalDelete } from "@/core/SwalAlert";

export default {
  props: {
    dataList: {},
    headers: {},
    editProduct: false,
    changeRemission: false,
    modelChange: Object,
    deleteItemRemmi: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      dialogRemi: false,
      currentItem: null,
    };
  },
  methods: {
    openModalEdit(item) {
      this.$emit("openModalEdit", item);
    },
    deleteModalRemmi(item) {
      this.dialogRemi = true;
      this.currentItem = item;
    },
    async deleteRecordRemmi(item) {
      const { isConfirmed } = await SwalDelete.fire({
        title: "¿Deseas eliminar este producto?",
        html:
          '<div class="pb-1"><b>' +
          this.$t("shipment_request.quantity") +
          "</b>" +
          item.quantity +
          "</br></div>" +
          '<div class="pb-1"><b>' +
          "Causes: " +
          "</b>" +
          item.productKey +
          "</div>" +
          '<div class="pb-1"><b>' +
          "Fecha de caducidad: " +
          "</b>" +
          item.expirationDate +
          "</div>" +
          '<div class="pb-1"><b>' +
          "Lote: " +
          "</b>" +
          item.lot +
          "</div>" +
          '<div class="pb-1"><b>' +
          "Fuente de Financiamiento: " +
          "</b>" +
          item.fiscalfund +
          "</div></br>" +
          "<div ><b>" +
          this.$t("shipment_request.description") +
          "</b>" +
          item.name +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.currentItem = item;
      let data = {
        item: this.currentItem,
      };
      this.$emit("deleteRecordRemmi", data);
      this.dialogRemi = false;
      this.dialogOrder = false;
    },
  },
};
</script>
