var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{key:"footerProps",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.modelTable.content,"fixed-header":"","dense":"","single-line":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"server-items-length":_vm.modelTable.totalElements,"options":_vm.options,"page":_vm.page,"loading":_vm.loading,"item-key":"idProductRemmison"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[(_vm.isFilter)?_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-3 col-xl-3"},[_c('v-select',{staticClass:"br",attrs:{"items":_vm.statusListFilter,"clearable":"","item-value":"value","label":"Tipo de entrada","data-vv-name":"select"},on:{"change":function($event){return _vm.statusFilterChange()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}}],null,false,1513015164),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1):_vm._e(),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"disabled":_vm.loading_search,"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.userName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.userName)+" "+_vm._s(item.userLastName)+" ")]}},{key:"item.remissionType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utilFront.typeRemissionName(item.remissionSubtype))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(_vm.isPDF)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-file-text mr-1"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]):_vm._e(),(
          _vm.isUpdate ||
          _vm.isAddProduct ||
          _vm.isAddProduct ||
          _vm.isUpdateProduct ||
          _vm.isChangeRemission
        )?_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"feather icon-more-vertical"})])]}}],null,true)},[_c('span',[_vm._v("Más opciones")])])]}}],null,true)},[(_vm.isUpdate)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.option(5, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"mdi mdi-square-edit-outline mr-1"}),_vm._v(" Editar información entrada")])],1)],1)],1):_vm._e(),(_vm.isAddProduct)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.option(3, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-file-plus mr-2"}),_vm._v("Agregar productos")])],1)],1)],1):_vm._e(),(_vm.isUpdateProduct)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.option(4, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-list mr-2"}),_vm._v("Editar productos")])],1)],1)],1):_vm._e(),(_vm.isChangeRemission)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.option(6, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-refresh-ccw mr-2"}),_vm._v("Cambio de entrada")])],1)],1)],1):_vm._e()],1):_vm._e(),(
          !_vm.isUpdate &&
          !_vm.isAddProduct &&
          !_vm.isAddProduct &&
          !_vm.isUpdateProduct &&
          !_vm.isChangeRemission &&
          !_vm.isPDF &&
          !_vm.isDetail
        )?_c('div',[_vm._v(" - ")]):_vm._e()]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }