<template>
  <v-autocomplete
    :disabled="isDisabled"
    v-model="idProductModel"
    outlined
    :items="productList"
    @change="productChange"
    :label="productLabel"
  >
    <template slot="item" slot-scope="data">
      <div class="pt-5">
        {{ data.item.text }}
      </div>
    </template>

    <template slot="selection" slot-scope="data">
      <div class="pt-5">
        {{ data.item.text }}
      </div>
    </template>

    <template v-slot:append>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="#05AF12"
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
import { ProductResquest } from "@/core/request";
import { mapState } from "vuex";
export default {
  props: {
    productLabel: "",
    idProduct: "",
  },
  data() {
    return {
      productList: [],
      isDisabled: false,
      loading: false,
      idProductModel: this.idProduct,
    };
  },
  computed: {
    ...mapState("remission", ["listProduct"]),
  },
  methods: {
    productChange() {
      this.$emit("productChange", this.idProductModel);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentClean() {
      this.idProductModel = "";
    },
    reloadComponent(idProduct) {
      this.idProductModel = idProduct;
    },
  },
  created() {
    //Mustro cargar lista
    this.loading = true;
    if (
      this.productList.length == 0 &&
      this.listProduct.productFormList.length > 0
    ) {
      this.productList = this.listProduct.productFormList;
      this.loading = false;
    }
    ProductResquest.findAllProducts().then((response) => {
      let { data, success } = response.data.data;
      if (success) {
        for (var [index, value] of Object.entries(data)) {
          index;
          let productItem = {
            text:
              value.productKey +
              " | " +
              value.name +
              " " +
              value.description +
              " | Presentación: " +
              value.unitMeasurementDescription,
            value: value.idProduct,
          };
          this.productList.push(productItem);
        }
      }
      this.loading = false;
    });
  },
};
</script>
