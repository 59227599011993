<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-body">
          <validation-observer ref="observerRemission">
            <form>
              <div class="row">
                <div class="col-sm-12 col-md-12 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="remission_ref"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model.trim="modelRemission.remissionkey"
                      :counter="100"
                      outlined
                      :maxlength="100"
                      oninput="this.value = this.value.toUpperCase()"
                      :error-messages="errors"
                      :label="$t('label.remission_ref')"
                      required
                    >
                    </v-text-field>
                  </validation-provider>
                </div>
                <div class="col-sm-12 col-md-6">
                  <validation-provider v-slot="{ errors }" name="remissionType">
                    <v-autocomplete
                      :error-messages="errors"
                      no-data-text="Sin resultados"
                      :items="remissionType"
                      item-value="value"
                      auto-select-first
                      v-model="modelRemission.remissionType"
                      label="Tipo de entrada (opcional)"
                      required
                      clear-icon="feather icon-x-circle"
                      clearable
                    >
                      <template v-slot:selection="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template v-slot:item="data">
                        {{ $t(data.item.name) }}
                      </template>
                    </v-autocomplete>
                  </validation-provider>
                </div>
                <div class="col-sm-12 col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="order"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="modelRemission.requisitionGobId"
                      :no-data-text="$t('label.no_results')"
                      :items="requisitionGovList"
                      :error-messages="errors"
                      :label="$t('label.order')"
                      data-vv-name="select"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="supplier"
                    rules="required"
                  >
                    <v-autocomplete
                      :items="suppliers"
                      v-model="modelRemission.suppliers"
                      :error-messages="errors"
                      :no-data-text="$t('label.no_results')"
                      required
                      :label="$t('label.supplier')"
                    >
                    </v-autocomplete>
                  </validation-provider>
                </div>
                <div class="col-sm-12 col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="bill"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model.trim="modelRemission.invoice"
                      :counter="30"
                      :maxlength="30"
                      :error-messages="errors"
                      :label="$t('label.bill')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="observation"
                    rules="min:1|max:2000"
                  >
                    <v-textarea
                      outlined
                      v-model="modelRemission.observations"
                      :error-messages="errors"
                      :label="$t('label.observation_')"
                      counter
                      :maxlength="2000"
                      rows="4"
                      append-icon="feather icon-message-square"
                      required
                      oninput="this.value = this.value.toUpperCase()"
                    />
                  </validation-provider>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState } from "vuex";
import remissionType from "@/assets/json/remissionType.json";
import moment from "moment";
export default {
  props: {
    modelRemission: Object,
  },
  data() {
    return {
      remissionType: remissionType,
    };
  },
  computed: {
    ...mapState("remission", {
      suppliers: "listSupplier",
      requisitionGovList: "listRequisition",
    }),
  },
  methods: {
    async validateForm() {
      return await this.$refs.observerRemission.validate();
    },
    getReference() {
      this.modelRemission.remissionkey = moment().format("DDMMYYYYhmm");
    },
    clear() {
      if (!this.modelRemission.idRemmision) {
        this.modelRemission.requisitionGobId = "";
        this.modelRemission.remissionkey = "";
        this.modelRemission.invoice = "";
        this.modelRemission.suppliers = "";
        this.modelRemission.remissionType = "";
        this.modelRemission.observations = "";
      }
    },
  },
  created() {
    this.clear();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
