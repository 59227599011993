var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observerRemission",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 pb-0"},[_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","error-messages":errors,"auto-select-first":"","items":_vm.productFormList,"label":_vm.$t('label.product')},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"pt-3 pb-3"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"selection",fn:function(data){return [_c('div',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}}],null,true),model:{value:(_vm.modelProduct.itemProduct),callback:function ($$v) {_vm.$set(_vm.modelProduct, "itemProduct", $$v)},expression:"modelProduct.itemProduct"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"expiry_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"date","label":_vm.$t('label.expiry_date'),"required":"","max":"2099-12-31","error-messages":errors,"min":_vm.currentDate},model:{value:(_vm.modelProduct.itemDate),callback:function ($$v) {_vm.$set(_vm.modelProduct, "itemDate", $$v)},expression:"modelProduct.itemDate"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('FiscalFoundComponent',{ref:"fiscalFund"})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"lot","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"maxlength":50,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('label.lot'),"required":""},model:{value:(_vm.modelProduct.itemLot),callback:function ($$v) {_vm.$set(_vm.modelProduct, "itemLot", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelProduct.itemLot"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":8,"maxlength":8,"error-messages":errors,"label":_vm.$t('label.quantity'),"oninput":"this.value = this.value.toUpperCase()","required":""},model:{value:(_vm.modelProduct.itemQuantity),callback:function ($$v) {_vm.$set(_vm.modelProduct, "itemQuantity", $$v)},expression:"modelProduct.itemQuantity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"cost_unity","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"0.00","counter":17,"maxlength":17,"prefix":"$","error-messages":errors,"label":_vm.$t('label.cost_unity'),"required":""},on:{"input":_vm.restrictDecimal},model:{value:(_vm.modelProduct.itemPrice),callback:function ($$v) {_vm.$set(_vm.modelProduct, "itemPrice", $$v)},expression:"modelProduct.itemPrice"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 col-md-4 pt-7 text-left"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.add_product"))+" ")])])])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }