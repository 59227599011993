<template>
  <v-dialog
    v-model="dialogM.dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue-fmedical">
        <v-toolbar-title class="pt-4">
          <p class="text-center">
            <i class="feather icon-package mr-1"></i>
            {{ $t("remission.remission_detail") }} |
            {{ model.idRemmision }}
          </p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialogM.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-md-12 pb-0">
            <div class="col-md-12 pb-0">
              <p class="pt-3">
                <i class="fa fa-info mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>{{ $t("title.title_info") }} </span>
                </strong>
              </p>
            </div>
            <div class="card border-light">
              <div class="card-header card-header-detail regular">
                <i class="fa fa-male mr-2 text-fmedical-blue" />
                <strong>
                  <span> {{ $t("label.responsible") }}:</span>
                </strong>
                {{ model.userBranchOfficeDTO.user.name }}
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i class="fa fa-paperclip mr-2 text-fmedical-blue"></i>
                      <strong>
                        <span> {{ $t("label.ID_remission") }}:</span>
                      </strong>
                      {{ model.idRemmision }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-file-text mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Referencia de entrada:</span>
                      </strong>
                      {{ model.remissionkey }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-clipboard  mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Tipo de entrada:</span>
                      </strong>
                      {{ model.remissionSubtypeName }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-0">
                      <i
                        class="feather icon-archive mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> {{ $t("label.supplier_") }}:</span>
                      </strong>
                      {{ model.suppliersName }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-0">
                      <i
                        class="feather icon-calendar mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> {{ $t("label.date_entry") }}:</span>
                      </strong>
                      {{ model.dateinput }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-0">
                      <i
                        class="feather icon-file-text mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Factura:</span>
                      </strong>
                      {{ model.invoice }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-12">
                    <p class="pt-0">
                      <i
                        class="feather icon-message-square mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Observación:</span>
                      </strong>
                      {{
                        model.observations
                          ? model.observations
                          : "Sin observaciones"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <v-data-table
              :headers="remmisionViewHeaders"
              :items="model.produdctsRemmision"
              :search="search"
              fixed-header
              height="auto"
              :footer-props="{
                'items-per-page-text': $t('table.item_table'),
                'items-per-page-all-text': $t('table.all'),
                'items-per-page-options': [15, 30, 50, 100],
              }"
              :loading-text="$t('table.loading')"
              :no-data-text="$t('table.no_results_available')"
              :no-results-text="$t('table.no_results_search')"
              dense
            >
              <template v-slot:top>
                <div class="row justify-end">
                  <div
                    class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
                  >
                    <v-text-field
                      v-model="search"
                      :label="$t('table.search')"
                    ></v-text-field>
                  </div>
                </div>
              </template>
            </v-data-table>
            <ButtonPrintComponent />
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  data() {
    return {
      search: "",
    };
  },
  components: {
    ButtonPrintComponent,
  },
  props: {
    dialogM: Object,
    model: {
      required: true,
      default: {},
    },
    remmisionViewHeaders: {
      require: true,
      default: {},
    },
  },
};
</script>
