var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"eager":"","fullscreen":"","persistent":_vm.persistent,"hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogM.dialogUpdate),callback:function ($$v) {_vm.$set(_vm.dialogM, "dialogUpdate", $$v)},expression:"dialogM.dialogUpdate"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue-fmedical"}},[_c('v-toolbar-title',{staticClass:"pt-4"},[_c('p',{staticClass:"text-center"},[_c('i',{staticClass:"ti-exchange-vertical mr-2"}),_vm._v(" "+_vm._s(_vm.$t("remission.remission_product"))+" "),_c('strong',[_vm._v("ID - "+_vm._s(_vm.currentItem.idProductRemmision))])])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogM.dialogUpdate = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-container',[_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"col-md-12 pb-0"},[_c('p',{staticClass:"pb-3"},[_c('i',{staticClass:"feather icon-repeat mr-2 text-fmedical-blue"}),_c('strong',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("remission.change_product")))])])]),_c('AllProductsRemissionComponent',{ref:"product",attrs:{"productLabel":_vm.$t('label.producto_available')},on:{"productChange":_vm.productChangeUpdate}})],1),_c('div',{staticClass:"col-md-12 text-right pt-0"},[_c('button',{staticClass:"btn btn-outline-success",on:{"click":_vm.updateProductFromStock}},[_c('i',{staticClass:"feather icon-repeat mr-2"}),_vm._v(" "+_vm._s(_vm.$t("remission.remission_product"))+" ")])]),_c('div',{staticClass:"col-12 pb-0 pt-0"},[_c('hr')]),_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"pb-3"},[_c('i',{staticClass:"ti-info-alt mr-2 text-fmedical-blue"}),_c('strong',[_c('span',[_vm._v("Información del producto ")])])]),_c('validation-observer',{ref:"observerUpdate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateProductRemision.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"expiry_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"date","label":_vm.$t('label.expiry_date'),"required":"","error-messages":errors},model:{value:(_vm.modelProduct.expirationDate),callback:function ($$v) {_vm.$set(_vm.modelProduct, "expirationDate", $$v)},expression:"modelProduct.expirationDate"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('FiscalFoundComponent',{ref:"fiscalFund"})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"lot","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"maxlength":50,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('label.lot'),"required":""},model:{value:(_vm.modelProduct.lot),callback:function ($$v) {_vm.$set(_vm.modelProduct, "lot", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelProduct.lot"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":8,"maxlength":8,"error-messages":errors,"label":_vm.$t('label.quantity'),"required":""},model:{value:(_vm.modelProduct.quantity),callback:function ($$v) {_vm.$set(_vm.modelProduct, "quantity", $$v)},expression:"modelProduct.quantity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"cost_unity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"0.0","counter":17,"maxlength":17,"prefix":"$","error-messages":errors,"label":_vm.$t('label.cost_unity'),"required":""},on:{"input":_vm.restrictDecimal},model:{value:(_vm.modelProduct.unitPrice),callback:function ($$v) {_vm.$set(_vm.modelProduct, "unitPrice", $$v)},expression:"modelProduct.unitPrice"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-save mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.update_info"))+" ")])])])])]}}])})],1),_c('div',{staticClass:"col-12 pb-0 pt-0"},[_c('hr')])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }