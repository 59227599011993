var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observerRemission"},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 pb-0"},[_c('validation-provider',{attrs:{"name":"remission_ref","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"outlined":"","maxlength":100,"oninput":"this.value = this.value.toUpperCase()","error-messages":errors,"label":_vm.$t('label.remission_ref'),"required":""},model:{value:(_vm.modelRemission.remissionkey),callback:function ($$v) {_vm.$set(_vm.modelRemission, "remissionkey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelRemission.remissionkey"}})]}}])})],1),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"remissionType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"no-data-text":"Sin resultados","items":_vm.remissionType,"item-value":"value","auto-select-first":"","label":"Tipo de entrada (opcional)","required":"","clear-icon":"feather icon-x-circle","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}}],null,true),model:{value:(_vm.modelRemission.remissionType),callback:function ($$v) {_vm.$set(_vm.modelRemission, "remissionType", $$v)},expression:"modelRemission.remissionType"}})]}}])})],1),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"no-data-text":_vm.$t('label.no_results'),"items":_vm.requisitionGovList,"error-messages":errors,"label":_vm.$t('label.order'),"data-vv-name":"select","required":""},model:{value:(_vm.modelRemission.requisitionGobId),callback:function ($$v) {_vm.$set(_vm.modelRemission, "requisitionGobId", $$v)},expression:"modelRemission.requisitionGobId"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"required":"","label":_vm.$t('label.supplier')},model:{value:(_vm.modelRemission.suppliers),callback:function ($$v) {_vm.$set(_vm.modelRemission, "suppliers", $$v)},expression:"modelRemission.suppliers"}})]}}])})],1),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"bill","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":30,"maxlength":30,"error-messages":errors,"label":_vm.$t('label.bill'),"required":""},model:{value:(_vm.modelRemission.invoice),callback:function ($$v) {_vm.$set(_vm.modelRemission, "invoice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelRemission.invoice"}})]}}])})],1),_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"observation","rules":"min:1|max:2000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","error-messages":errors,"label":_vm.$t('label.observation_'),"counter":"","maxlength":2000,"rows":"4","append-icon":"feather icon-message-square","required":"","oninput":"this.value = this.value.toUpperCase()"},model:{value:(_vm.modelRemission.observations),callback:function ($$v) {_vm.$set(_vm.modelRemission, "observations", $$v)},expression:"modelRemission.observations"}})]}}])})],1)])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }