<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogM.dialogUpdate"
      eager
      fullscreen
      :persistent="persistent"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="ti-exchange-vertical mr-2"></i>
              {{ $t("remission.remission_product") }}
              <strong>ID - {{ currentItem.idProductRemmision }}</strong>
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialogM.dialogUpdate = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <div class="pt-5">
            <div class="col-md-12 pb-0">
              <p class="pb-3">
                <i class="feather icon-repeat mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("remission.change_product") }}</span>
                </strong>
              </p>
              <AllProductsRemissionComponent
                ref="product"
                :productLabel="$t('label.producto_available')"
                v-on:productChange="productChangeUpdate"
              />
            </div>
            <div class="col-md-12 text-right pt-0">
              <button
                class="btn btn-outline-success"
                @click="updateProductFromStock"
              >
                <i class="feather icon-repeat mr-2"></i>
                {{ $t("remission.remission_product") }}
              </button>
            </div>
            <div class="col-12 pb-0 pt-0">
              <hr />
            </div>
            <div class="col-md-12">
              <p class="pb-3">
                <i class="ti-info-alt mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>Información del producto </span>
                </strong>
              </p>
              <validation-observer ref="observerUpdate" v-slot="{ invalid }">
                <form @submit.prevent="updateProductRemision">
                  <div class="row">
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="expiry_date"
                        rules="required"
                      >
                        <v-text-field
                          type="date"
                          :label="$t('label.expiry_date')"
                          required
                          :error-messages="errors"
                          v-model="modelProduct.expirationDate"
                        >
                        </v-text-field>
                      </validation-provider>
                    </div>

                    <div class="col-md-4">
                      <FiscalFoundComponent ref="fiscalFund" />
                    </div>
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="lot"
                        rules="required|max:50"
                      >
                        <v-text-field
                          v-model.trim="modelProduct.lot"
                          :counter="50"
                          :maxlength="50"
                          :error-messages="errors"
                          oninput="this.value = this.value.toUpperCase()"
                          :label="$t('label.lot')"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="quantity"
                        rules="required|numeric"
                      >
                        <v-text-field
                          v-model="modelProduct.quantity"
                          :counter="8"
                          :maxlength="8"
                          :error-messages="errors"
                          :label="$t('label.quantity')"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="cost_unity"
                        rules="required"
                      >
                        <v-text-field
                          @input="restrictDecimal"
                          placeholder="0.0"
                          :counter="17"
                          :maxlength="17"
                          prefix="$"
                          v-model="modelProduct.unitPrice"
                          :error-messages="errors"
                          :label="$t('label.cost_unity')"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-12 text-right">
                      <button
                        class="btn btn-outline-success"
                        type="submit"
                        :disabled="invalid"
                      >
                        <i class="feather icon-save mr-2"></i>
                        {{ $t("global.update_info") }}
                      </button>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <div class="col-12 pb-0 pt-0">
              <hr />
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FiscalFoundComponent from "@/common/select/FiscalFoundComponent";
import AllProductsRemissionComponent from "@/common/select/AllProductsRemissionComponent";
import { RemissionRequest, StockRequest } from "@/core/request";
import { UtilFront } from "@/core";
import moment from "moment";
import { SwalConfirm } from "@/core/SwalAlert";

/* Vuex */
import { mapState } from "vuex";
export default {
  props: {
    modelProduct: {
      type: Object,
      required: true,
    },
    dialogM: Object,
    modelRemission: Object,
    currentItem: Object,
    getProductRemission: Function,
    alert: Function,
    originId: 0,
  },
  data() {
    return {
      suppliers: [],
      productFormList: [],
      productServerList: [],
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      loading: false,
      persistent: false,
    };
  },
  computed: {
    ...mapState("locales", ["lang"]),
    ...mapState("remission", ["listProduct"]),
  },
  methods: {
    async isRemission() {
      return this.$route.path == "/entradas/proveedor";
    },
    setData(item) {
      this.$refs.fiscalFund.reloadComponent(item.fiscalFund.idFiscalFund);
      this.$refs.product.reloadComponent(item.products.idProduct);
    },
    restrictDecimal() {
      this.modelProduct.unitPrice = this.modelProduct.unitPrice.match(
        /^\d+\.?\d{0,7}/
      );
    },
    productChangeUpdate(idProduct) {
      this.modelProduct.productId = idProduct;
    },
    async updateProductFromStock() {
      this.persistent = true;
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html: "El producto sera cambiado.",
      });
      if (!isConfirmed) {
        this.persistent = false;
        return;
      }
      this.persistent = false;
      this.loading = UtilFront.getLoadding();
      let validate = await this.isRemission();
      //Solo envio la informacion que sea necesaria
      let modelUpdate = {
        idProductRemmision: this.modelProduct.idProductRemmision,
        productId: this.modelProduct.productId,
        remissionType: validate ? 1 : 2,
      };
      modelUpdate.userBranchOffice = {
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId.idBranchoffice,
      };
      StockRequest.changeProductStock(modelUpdate)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Update_Product_0001");
            this.getProductRemission(this.modelRemission, 4);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.dialogM.dialogUpdate = false;
        });
    },
    /* Actualizar producto de la remision */
    async updateProductRemision() {
      this.persistent = true;
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html: "La información del producto sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      //Muestro en carga a los elementos
      this.loading = UtilFront.getLoadding();
      let validate = await this.isRemission();
      //Solo envio la informacion que sea necesaria
      let modelUpdate = {
        idProductRemmision: this.currentItem.idProductRemmision,
        productId: this.currentItem.products.idProduct,
        remissionType: validate ? 1 : 2,
      };
      //Caducidad
      if (
        moment(this.currentItem.expirationDate, "DD/MM/YYYY").format(
          "yyyy-MM-DD"
        ) != this.modelProduct.expirationDate
      )
        modelUpdate.expirationDate = this.modelProduct.expirationDate;
      //Lotes
      if (this.currentItem.lot != this.modelProduct.lot)
        modelUpdate.lot = this.modelProduct.lot;
      //Cantidad
      if (this.currentItem.quantity != this.modelProduct.quantity)
        modelUpdate.quantity = this.modelProduct.quantity;
      //Precio unitario
      if (this.currentItem.unitPrice != this.modelProduct.unitPrice)
        modelUpdate.unitPrice = this.modelProduct.unitPrice[0];
      //Fuente de financiamiento
      if (
        this.currentItem.idFiscalFund != this.$refs.fiscalFund.getFiscalFund()
      )
        modelUpdate.fiscalFund = {
          idFiscalFund: this.$refs.fiscalFund.getFiscalFund(),
        };
      //Informacion del usuario y la sucursal
      modelUpdate.userBranchOffice = {
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId.idBranchoffice,
      };
      RemissionRequest.updateProductRemmision(modelUpdate)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Succces_Remmision_Update_0001");
            this.getProductRemission(this.modelRemission, 4);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.dialogM.dialogUpdate = false;
        });
    },
  },
  created() {},
  components: {
    ValidationProvider,
    ValidationObserver,
    FiscalFoundComponent,
    AllProductsRemissionComponent,
  },
};
</script>
