<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :search="search"
      :items="modelTable.content"
      fixed-header
      dense
      single-line
      key="footerProps"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
      item-key="idProductRemmison"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div v-if="isFilter" class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-select
              class="br"
              v-model="statusFilter"
              :items="statusListFilter"
              clearable
              @change="statusFilterChange()"
              item-value="value"
              label="Tipo de entrada"
              data-vv-name="select"
            >
              <template v-slot:selection="data">
                {{ $t(data.item.name) }}
              </template>
              <template v-slot:item="data">
                {{ $t(data.item.name) }}
              </template></v-select
            >
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              :disabled="loading_search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.userName`]="{ item }">
        {{ item.userName }} {{ item.userLastName }}
      </template>
      <template v-slot:[`item.remissionType`]="{ item }">
        {{ utilFront.typeRemissionName(item.remissionSubtype) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDetail">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="isPDF">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-file-text mr-1"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>

        <v-menu
          offset-x
          left
          v-if="
            isUpdate ||
            isAddProduct ||
            isAddProduct ||
            isUpdateProduct ||
            isChangeRemission
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <i class="feather icon-more-vertical"></i>
                </v-btn>
              </template>
              <span>Más opciones</span>
            </v-tooltip>
          </template>

          <!-- @click="getPatient(item)" -->
          <v-list dense nav v-if="isUpdate">
            <v-list-item @click="option(5, item)">
              <v-list-item-content>
                <v-list-item-title>
                  <i class="mdi mdi-square-edit-outline mr-1"></i>
                  Editar información entrada</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="isAddProduct">
            <v-list-item @click="option(3, item)">
              <v-list-item-content>
                <v-list-item-title
                  ><i class="feather icon-file-plus mr-2"></i>Agregar
                  productos</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="isUpdateProduct">
            <v-list-item @click="option(4, item)">
              <v-list-item-content>
                <v-list-item-title
                  ><i class="feather icon-list mr-2"></i>Editar
                  productos</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="isChangeRemission">
            <v-list-item @click="option(6, item)">
              <v-list-item-content>
                <v-list-item-title
                  ><i class="feather icon-refresh-ccw mr-2"></i>Cambio de
                  entrada</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          v-if="
            !isUpdate &&
            !isAddProduct &&
            !isAddProduct &&
            !isUpdateProduct &&
            !isChangeRemission &&
            !isPDF &&
            !isDetail
          "
        >
          -
        </div>
      </template>
    </v-data-table>
    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import remissionFilter from "@/assets/json/remissionType.json";

export default {
  props: {
    headers: {},
    isDelete: false,
    isUpdate: false,
    isPDF: false,
    isButton: false,
    isDetail: false,
    isAddProduct: false,
    isUpdateProduct: false,
    isChangeRemission: false,
    isFilter: true,
    changeAction: Function,
    remissionType: {
      type: Number,
      require: true,
    },
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      options: {},
      loading: false,
      page: 1,
      time: 1,
      permissions: PermissionConstants,
      utilFront: this.$UtilFront,
      loading_search: false,
      statusListFilter: remissionFilter,
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /**
     * Un método que se llama cuando cambia la paginación.
     * @method
     */
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      if (this.time > 1)
        this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      this.time++;
      this.pagesAtributes.currentPage = this.options.page - 1;
      this.pagesAtributes.itemsPerPage = this.options.itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    /**
     * Método que se llama cuando se utiliza la barra de búsqueda.
     * @method
     */
    async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    reloadPages() {
      this.page = 1;
    },
    async statusFilterChange() {
      if (this.statusFilter) {
        this.pagesAtributes.sortFilters.remissionTypeSelectFilter = this.statusFilter;
      } else {
        delete this.pagesAtributes.sortFilters.remissionTypeSelectFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    option(action, item) {
      this.changeAction(action, item);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    receiveShipment(item) {
      this.$emit("receiveShipment", item);
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
};
</script>
