<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-body">
          <validation-observer ref="observerRemission" v-slot="{ invalid }">
            <form @submit.prevent="validate">
              <div class="row">
                <div class="col-md-12 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="product"
                    rules="required"
                  >
                    <v-autocomplete
                      outlined
                      :error-messages="errors"
                      auto-select-first
                      :items="productFormList"
                      v-model="modelProduct.itemProduct"
                      :label="$t('label.product')"
                    >
                      <template slot="item" slot-scope="data">
                        <div class="pt-3 pb-3">
                          {{ data.item.text }}
                        </div>
                      </template>

                      <template slot="selection" slot-scope="data">
                        <div class="pt-5">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="expiry_date"
                    rules="required"
                  >
                    <v-text-field
                      type="date"
                      :label="$t('label.expiry_date')"
                      required
                      max="2099-12-31"
                      :error-messages="errors"
                      :min="currentDate"
                      v-model="modelProduct.itemDate"
                    >
                    </v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <FiscalFoundComponent ref="fiscalFund" />
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="lot"
                    rules="required|max:50"
                  >
                    <v-text-field
                      v-model.trim="modelProduct.itemLot"
                      :counter="50"
                      :maxlength="50"
                      :error-messages="errors"
                      oninput="this.value = this.value.toUpperCase()"
                      :label="$t('label.lot')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="quantity"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="modelProduct.itemQuantity"
                      :counter="8"
                      :maxlength="8"
                      :error-messages="errors"
                      :label="$t('label.quantity')"
                      oninput="this.value = this.value.toUpperCase()"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="cost_unity"
                    rules="required|double"
                  >
                    <v-text-field
                      @input="restrictDecimal"
                      placeholder="0.00"
                      :counter="17"
                      :maxlength="17"
                      prefix="$"
                      v-model="modelProduct.itemPrice"
                      :error-messages="errors"
                      :label="$t('label.cost_unity')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>

                <div class="col-sm-12 col-md-4 pt-7 text-left">
                  <button
                    type="submit"
                    class="btn btn-outline-success"
                    :disabled="invalid"
                  >
                    <i class="feather icon-plus mr-1"></i>
                    {{ $t("global.add_product") }}
                  </button>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FiscalFoundComponent from "@/common/select/FiscalFoundComponent";
/* Vuex */
import { mapState } from "vuex";
import moment from "moment";
const numeral = require("numeral");
import { SwalConfirm } from "@/core/SwalAlert";

export default {
  props: {
    modelProduct: Object,
    addProductListComponent: Function,
    alert: Function,
    productTableList: Array,
    validation: {
      default: false,
    },
  },
  data() {
    return {
      suppliers: [],
      productFormList: [],
      productServerList: [],
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      currentDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState("locales", ["lang"]),
    ...mapState("remission", ["listProduct"]),
  },
  methods: {
    // Form validation
    async validateForm() {
      return await this.$refs.observerRemission.validate();
    },
    restrictDecimal() {
      this.modelProduct.itemPrice = this.modelProduct.itemPrice.match(
        /^\d+\.?\d{0,7}/
      );
    },
    async validate() {
      let isValid = await this.$refs.fiscalFund.validateFormFiscal();
      if (isValid) {
        this.addProduct();
      }
    },
    async addProduct() {
      if (
        this.modelProduct.itemProduct != null &&
        this.modelProduct.itemQuantity > 0
      ) {
        var id = this.modelProduct.itemProduct;
        let itemServer = this.productServerList.find(function(prodct, index) {
          index;
          if (prodct.idProduct == id) {
            return prodct;
          }
        });
        let idFiscalFund = this.$refs.fiscalFund.getFiscalFund();
        let fiscalfund = this.$refs.fiscalFund.currentFiscalFund();
        /* Validación de LOTE Y CAUSES */
        if (this.validation) {
          const found = this.productTableList.find(
            (element) =>
              element.lot.trim().toUpperCase() ==
                this.modelProduct.itemLot.trim().toUpperCase() &&
              itemServer.productKey == element.productKey
          );
          if (found != undefined) {
            const { isConfirmed } = await SwalConfirm.fire({
              html:
                "Se ha encontrado un producto en la entrada con la misma información que intenta ingresar:" +
                " <br><br>Causes:<b> " +
                itemServer.productKey +
                "</b> <br>Lote:<b> " +
                this.modelProduct.itemLot.trim().toUpperCase() +
                "</b>",
              icon: "warning",
              confirmButtonText:
                '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
            });
            if (!isConfirmed) {
              return;
            }
          }
        }

        /* let value = Number(this.modelProduct.itemPrice);
        this.modelProduct.itemPrice = value.toFixed(5); */

        let idTemp = Date.now();

        this.$refs.observerRemission.validate;
        let itemProductList = {
          idProductRemmision: idTemp,
          products: {
            idProduct: this.modelProduct.itemProduct,
          },
          fiscalFund: {
            idFiscalFund: idFiscalFund,
          },
          lot: this.modelProduct.itemLot,
          quantity: parseInt(this.modelProduct.itemQuantity),
          expirationDate: this.modelProduct.itemDate,
          unitPrice: parseFloat(this.modelProduct.itemPrice),
          status: 1,
        };
        /** FindElement */

        let itemProductListTable = {
          id: idTemp,
          productKey: itemServer.productKey,
          name: itemServer.description,
          unit: itemServer.unitMeasurementDescription,
          expirationDate: moment(this.modelProduct.itemDate).format(
            "DD/MM/YYYY"
          ),
          lot: this.modelProduct.itemLot,
          quantity: this.modelProduct.itemQuantity,
          costo: Intl.NumberFormat("es-MX", {
            maximumSignificantDigits: 18,
          }).format(this.modelProduct.itemPrice),
          fiscalfund: fiscalfund,
        };
        this.addProductListComponent(itemProductListTable, itemProductList);
      } else {
        this.alert("error", "Empty_Fields_0004");
      }
    },
    clearAddProduct() {
      this.modelProduct.itemName = "";
      this.modelProduct.itemProduct = "";
      this.modelProduct.itemDate = "";
      this.modelProduct.itemLot = "";
      this.modelProduct.itemQuantity = "";
      this.modelProduct.itemPrice = "0.0";
      this.$refs.observerRemission.reset();
    },
  },
  created() {
    this.modelProduct.itemPrice = "0.0";
    this.productFormList = this.listProduct.productFormList;
    this.productServerList = this.listProduct.productServerList;
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiscalFoundComponent,
  },
};
</script>
